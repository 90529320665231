import { useEffect } from "react";

import disc from "@assets/img/disc.webp";
import logo from "./assets/logo_valentine_dark.png";
import kissing from "@assets/img/kissing_emoji.png";
import sparklingHeart from "@assets/img/sparkling_heart_emoji.png";
import musicalNotes from "@assets/img/musical_notes_emoji.png";
import doubleNote from "@assets/img/double_note_emoji.png";
import textOverflowFade from "./assets/white_text_overflow_fade.png";

import { ThreeDots } from "react-loader-spinner";
import { useImageExporter } from "@hooks/useImageExporter";
import useSession from "@hooks/useSession";

const ValentinePattern1 = ({ content, isMysong, onSharing }) => {
  const { exportedURL, exportedFile, exportRefCallback } = useImageExporter();
  const { user } = useSession();

  useEffect(() => {
    if (!exportedFile) return;
    onSharing(exportedFile);
  }, [exportedFile]);

  return (
    <>
      <div className="h-0 w-0 overflow-hidden">
        <div
          className="relative flex min-h-[715px] w-[960px] flex-col justify-between bg-white px-[86px] pt-[42px] pb-[86px]"
          ref={exportRefCallback}
        >
          <img
            src={doubleNote}
            alt="Double Notes"
            className="absolute bottom-[260px] right-[90px] w-[32px]"
          />
          <img
            src={musicalNotes}
            alt="Musical Notes"
            className="absolute bottom-[300px] right-[140px] w-[50px]"
          />
          <img
            src={sparklingHeart}
            alt="Sparkling Heart"
            className="absolute bottom-[60px] right-[60px] w-[160px]"
          />

          <div>
            <div className="flex items-center">
              <div className="relative mt-[40px] flex h-[180px] w-[180px] shrink-0 items-center justify-center">
                <img
                  className="absolute inset-0 h-full w-full select-none object-contain"
                  src={content.disc?.disc || disc}
                  alt="disc"
                />
                <img
                  className="absolute h-[52px] w-[52px] shrink-0 rounded-full object-contain "
                  src={content.song.thumbnails[0].url}
                  alt="thumbnail"
                  referrerPolicy="no-referrer"
                  crossOrigin="anonymous"
                />
                <img
                  className="absolute top-0 left-0 h-[60px] w-[60px] select-none object-contain"
                  src={kissing}
                  alt="kissing face"
                />
              </div>
              <div className="ml-[32px] flex min-w-0 flex-col">
                <div className="flex w-[560px] flex-row">
                  <span
                    className={`flex w-[460px] flex-row truncate text-[44px] font-bold italic leading-[2] text-black`}
                  >
                    {content.song.title}
                  </span>
                  <div
                    style={{
                      backgroundImage: `url(${textOverflowFade})`,
                    }}
                    className="relative top-[30px] right-[30px] h-16 w-12 border-none bg-contain"
                  />
                </div>

                <span
                  className={` -mt-[28px] truncate text-[42px] font-light italic leading-[2] text-[#C1C1C1]`}
                >
                  {content.song.artist}
                </span>
              </div>
            </div>
          </div>

          {isMysong && (
            <span className="mt-[20px]  text-[32px] font-bold text-black">
              @{user.username}
            </span>
          )}
          <div className="mt-[10px] w-full px-[8px]">
            <p
              style={{
                wordBreak: "break-word",
                whiteSpace: "pre-line",
                // "-webkit-text-stroke": "0.5px white",
              }}
              className=" pr-[170px] text-[48px]  font-bold italic text-black"
            >
              {content.message}
            </p>
          </div>
          <div className="mt-[32px] mb-[48px] flex w-full flex-row items-center px-[8px] pr-[120px] text-[28px] text-black">
            <span
              style={{
                wordBreak: "break-word",
                whiteSpace: "pre-line",
                // "-webkit-text-stroke": "0.5px white",
              }}
              className="gimmesong-secondary-font ml-2"
            >
              Happy Valentine from
            </span>

            <span
              style={{
                wordBreak: "break-word",
                whiteSpace: "pre-line",
                // "-webkit-text-stroke": "0.5px white",
              }}
              className="gimmesong-secondary-font ml-4 font-extrabold"
            >
              Anonymous
            </span>
          </div>

          <img
            className="mr-[10px] mt-[12px] w-[300px]"
            src={logo}
            alt="gimmesong logo"
          />
        </div>
      </div>

      {exportedURL ? (
        <div className="mt-3 flex flex-col items-center rounded-3xl border">
          <img
            className="w-full rounded-3xl"
            src={exportedURL}
            alt={`inbox-widget`}
          />{" "}
        </div>
      ) : (
        <ThreeDots
          height="60"
          width="60"
          radius="9"
          color="#8583D6"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      )}
    </>
  );
};
export default ValentinePattern1;
