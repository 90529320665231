import { createContext, useState } from "react";

import heartEyesEmoji from "@assets/img/heart_eyes_emoji.png";
import roseEmoji from "@assets/img/rose_emoji.png";
import sparklingHeartEmoji from "@assets/img/sparkling_heart_emoji.png";

import GimmesongAPI from "@lib/gimmesong_api";

export const DiscListContext = createContext();

function DiscListProvider({ children }) {
  const [isLoading, setLoading] = useState(false);

  const [discs, setDiscs] = useState([]);

  const fetchDiscs = async () => {
    setLoading(true);
    let respond = await GimmesongAPI.User.queryVinylStyleInventory();

    let result = [];
    for (let i = 0; i < respond.disc.length; i++) {
      for (let j = 0; j < respond.emoji.length; j++) {
        result.push({
          disc: respond.disc[i],
          emoji: respond.emoji[j],
        });
      }
    }

    setDiscs(result);
    setLoading(false);
  };

  const store = {
    state: { isLoading },
    data: { discs },
    action: { fetchDiscs },
  };

  return (
    <DiscListContext.Provider value={store}>
      {children}
    </DiscListContext.Provider>
  );
}

export default DiscListProvider;
